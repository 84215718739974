
/*GOOGLE FONTS*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700');


/*FRAMEWORKS*/
@import url("vendor/bootstrap/bootstrap.min.css");
@import url("vendor/slick/slick.css");
@import url("vendor/sweetalert/sweetalert2.min.css");
@import url("vendor/animate/animate.css");
@import url("vendor/fancybox/jquery.fancybox.min.css");



/*-----GENERAL-----*/

img[src$='.svg'] {
    display: none;
}

svg g, svg path {
    fill: inherit;
}

body{
	font-family: 'Montserrat', sans-serif;
	overflow-x: hidden;
}

.principal-color{
	color: #3e4095;
}

.section{
	padding-top: 60px;
	padding-bottom: 60px;
}

.section.interna{
	padding-top: 0;
	padding-bottom: 60px;
}

.titles{
	color: #3e4095;
	word-wrap: break-word;
}

.section .titles{
	font-size: 30px;
}

.texts{
	color: #3e3e3e;
}

.box-shadow{
	box-shadow: 0 5px 10px rgba(0,0,0,0.1);
}

.horiz-ruler{
	height: 5px;
	border-radius: 60px;
	background-color: #ed3237;
}

.btn-custom{
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 15px;
	padding-right: 15px;
	background-color: transparent;
	border: 2px solid #3e4095;
	color: #3e4095;
	font-weight: bold;
	font-size: 12px;
}

.btn-custom:focus{
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 15px;
	padding-right: 15px;
	background-color: transparent;
	border: 2px solid #3e4095;
	color: #3e4095;
	font-weight: bold;
	font-size: 12px;
}

.slick-dots{
	display: none !important;
}

.breadcrumb{
	background-color: #fff;
}

.breadcrumb li{
	font-size: 12px;
	color: #3e4095 !important;
}

.breadcrumb li a{
	color: #3e4095 !important;
	font-weight: bold;
}

.page-header{
	height: 270px;
	background-image: url('../images/page-header.jpg');
	background-size: cover;
	background-position: center;
}

.page-header-dynamic{
	height: 270px;
}

.page-header-dynamic.nivel{
	height: 480px;
	position: relative;
	overflow: hidden;
	background-color: rgba(62, 64, 149, 1);
	background-blend-mode: multiply;
}

.page-header-dynamic.nivel .overlay{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
  	flex-direction: column;
  	justify-content: center;
}


.page-header-dynamic.nivel .overlay .nivel-wrap{
	position: relative;
	width: 50%;
}

.page-header-dynamic.nivel .overlay .nivel-wrap .nivel-phrase{
	font-size: 34px;
	font-weight: bold;
	color: #fff;
	line-height: 38px;
	margin-bottom: 10px !important;
}

.page-header-dynamic.nivel .overlay .nivel-wrap .phrase-autor{
	font-size: 20px;
	color: #fff;
}


.pagination{
	display:inline-block;
}

.pagination > li{
	display:inline;
}

.pagination > li > a, .pagination > li > span{
	position:relative;
    float:left;
    padding:8px 14px;
    margin-left:-1px;
    line-height:1.42857143;
    color:#002b51;
    text-decoration:none;
    background-color:#fff;
    border:1px solid #ddd;
}

.pagination > li:first-child > a, .pagination > li:first-child > span{
	margin-left:0;
    border-top-left-radius:4px;
    border-bottom-left-radius:4px;
}

.pagination > li:last-child > a, .pagination > li:last-child > span{
    border-top-right-radius:4px;
    border-bottom-right-radius:4px;
}

.pagination > .disabled > a{
	color:#777;
    cursor:not-allowed;
    background-color:#fff;
    border-color:#ddd;
}

.box-arquivos{
	padding: 0;
}

.box-arquivos .list-arquivos{
	display: block;
	position: relative;
	padding: 14px 15px 14px 30px;
	list-style: none;
	border-bottom: 1px solid rgba(0,0,0,0.125);
}

.box-arquivos .list-arquivos a{
	display: block;
	text-decoration: none;
	color: #3e3e3e;
}

.box-arquivos .list-arquivos:last-child{
	border-bottom: 0;
}


.box-noticias{
	padding: 0;
}

.box-noticias .list-noticias{
	display: block;
	position: relative;
	padding: 14px 15px 14px 15px;
	list-style: none;
	border-bottom: 1px solid rgba(0,0,0,0.125);
}


.box-noticias .list-noticias a{
	font-size: 12px;
	display: block;
	text-decoration: none;
	color: #3e3e3e;
}

.box-noticias .list-noticias .tag{
	display: inline-block;
	font-size: 10px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 5px;
	padding-bottom: 5px;
	border-radius: 50px;
	background-color: #3e4095;
	color: #fff;
	margin-bottom: 10px;
}

.box-noticias .list-noticias:last-child{
	border-bottom: 0;
}

.page-text{
	word-wrap: break-word;
}

.page-text p{
	text-align: justify;
}

.page-text img{
	height: 120px;
}

.noticia-date{
	font-size: 12px;
}

.noticia-foto{
	width: 150px;
}

.img-galeria{
	position: relative;
	overflow: hidden;
}

.img-galeria img{
	transition: 300ms all;
}

.galeria-overlay{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	transition: 300ms all ease;
}

.galeria a:hover .img-galeria img{
	transform: scale(1.1);
}

.galeria a:hover .galeria-overlay{
	background-color: rgba(62, 64, 149, 0.7);
	color: #fff;
}

.search-icon{
	position: relative;
	margin-top: -25%;
	width: 100%;
	text-align: center;
	color: transparent;
	transition: 300ms all ease;
	font-size: 20px;
}

.galeria a:hover .search-icon{
	margin-top: 25%;
	color: #fff;
}

/*-----HEADER-----*/


.top-bar{
	width: 100%;
	background-color: #3e4095;
	color: #fff;
}

.top-bar ul{
	padding-left: 0;
}

.top-bar .social-medias li{
	float: left;
	list-style: none;
	text-align: center;
	width: 25px;
}

.top-bar .social-medias li a .social-icon{
	transition: 500ms all;
}

.top-bar .social-medias li a .social-icon:hover{
	background-color: #fff;
}

.top-bar .social-medias li a .social-icon:hover i{
	color: #3e4095;
}

.top-bar .only-sm{
	overflow: hidden;
	display: block;
	height: 0;
	transition: all 300ms;
}

.top-bar a{
	color: #fff;
	font-size: 12px;
}

.top-bar p{
	margin: 0 !important;
}

.top-bar .phone-icon{
	fill: #ffffff;
	height: 12px;
}

.navbar{
	box-shadow: 2px 3px 10px rgba(0,0,0,0.2);
	transition: 500ms all;
}

.navbar .nav-link{
	font-weight: bold;
	font-size: 12px;
	transition: 400ms all;
}


.navbar .nav-item{
	font-weight: bold;
	font-size: 12px;
	transition: 400ms all;
}

.navbar .nav-item.active .nav-link{
	color: rgba(0,0,0,.8);
}

.navbar .nav_logo{
	height: 80px;
	transition: 500ms all;
}

.navbar .nav_logo.scrolled{
	height: 50px;
}

.navbar .nav-item .btn-menu{
	background-color: #fff !important;
	border: solid 1px rgba(0,0,0,0.2);
	width: 40px;
}


.navbar .nav-item .btn-menu:hover{
	background-color: #f7f7f7  !important;
	color: #3e3e3e;
}

.navbar .nav-item.dropdown .dropdown-toggle:after{
	color: #3e4095;
}

.navbar .nav-item.dropdown .dropdown-menu .dropdown-item{
	font-size: 12px;
}

.tooltip-inner {
	padding-top: 8px;
	padding-bottom: 8px;
	font-size: 12px;
    color: #000;
    background-color: #fff;
    box-shadow: 0px 0px 7px rgba(0,0,0,0.2);

}

.tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.bs-tooltip-bottom .arrow::before {
    border-width: 0 5px 5px;
    border-bottom-color: #fff;
}



/*-----CARROSSEL-----*/



.carousel-indicators li{
	width: 10px;
	height: 10px;
	border-radius: 100%;
	background-color: #fff !important;
}

.carousel-indicators li.active{
	background-color: #3e4095 !important;
}


/*-----SEÇÃO HISTÓRIA-----*/


#historia{
	background-image: url('../images/bg1.jpg');
	background-size: cover;
	background-position: right top;
}

#historia .titles{
	margin-top: 100px;
}

#historia .niveis{
	height: 250px;
	background-color: #fff;
	transition: all 500ms;
}

#historia .niveis a,#historia .niveis a:hover,#historia .niveis a:active{
	text-decoration: none;
	color: inherit;
}

#historia .niveis:hover{
	background-image: url('../images/bg2.jpg');
	background-size: cover;
	transform: scale(1.05);
}

#historia .niveis .icon{
	fill: #3e4095;
	height: 50px;
	max-width: 100%;
}

#historia .niveis:hover .icon{
	fill: #fff;
}

#historia .niveis .title{
	color: #3e4095;
}

#historia .niveis p{
	line-height: 10px;
}

#historia .niveis:hover p{
	color: #fff;
}

#historia .historia-text{
	margin-top: 170px;
}

#historia .image{
	height: 580px;
	margin-bottom: -60px !important;
}



/*-----SEÇÃO NIVEL-----*/


.project .icon-wrap{
	height: 70px;
	width: 70px;
	border: solid #3e4095 3px;
	border-radius: 100%;
	transition: 500ms all;
}

.project:hover .icon-wrap{
	transform: scale(1.1);
}

.project .icon-wrap .project-icon{
	fill: #3e4095;
}

.project p{
	font-size: 13px;
}



/*-----SEÇÃO ESTRUTURA-----*/




#estrutura{
	background-image:url('../images/bg3.jpg');
	background-size: cover;
}

#estrutura .structures .icon{
	height: 50px;
	fill: #ffffff;
}

#estrutura .structures p{
	font-size: 12px;
	color: #fff;
	margin-top: 15px;
}

#estrutura .structures .desc{
	color: #a8a9cb;
}

#estrutura .structures .horiz-ruler{
	margin-top: 10px;
	height: 2px;
	width: 20%;
}


/*-------SEÇÃO NOTÍCIAS------*/


#noticias{
	background-color: #f5f5f5;
}

#noticias a, #noticias a:hover, #noticias a:active, .list-noticias a, .list-noticias a,:hover .list-noticias a:active{
	list-style: none;
	text-decoration: none;
}

#noticias .link-noticia, .list-noticias .link-noticia{
	background-color: #fff;
	height: 300px;
	transition: all 500ms;
}

#noticias .link-noticia:hover, .list-noticias .link-noticia:hover{
	transform: scale(1.05);
}

#noticias .link-noticia .description, .list-noticias .link-noticia .description{
	padding-left: 30px;
	padding-right: 30px;
}

#noticias .link-noticia .description .tag, .list-noticias .link-noticia .description .tag{
	display: inline-block;
	font-size: 10px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 5px;
	padding-bottom: 5px;
	border-radius: 50px;
	background-color: #3e4095;
	color: #fff;
	margin-bottom: 10px;
}

#noticias .link-noticia .description p, .list-noticias .link-noticia .description p{
	font-size: 14px;
	line-height: 15px;
}

#noticias .link-noticia .description .desc, .list-noticias .link-noticia .description .desc{
	font-size: 12px;
	margin-top: 5px;
	height: 40px;
	width: 100%;
	white-space: nowrap;
    text-overflow: ellipsis;
	display: block;
    overflow: hidden;
	word-wrap: break-word;
}


/*------SEÇÃO EVENTOS------*/


#eventos{
	background-color: #f5f5f5;
}

#eventos a, #eventos a:hover, #eventos a:active{
	list-style: none;
	text-decoration: none;
}

#eventos .lista-eventos{
	transition: all 500ms;
	height: 220px;
	padding-top: 160px;
	padding-left: 20px;
	padding-right: 20px;
}

#eventos .lista-eventos h3{
	margin-bottom: 0;
	white-space: nowrap;
    text-overflow: ellipsis;
	display: block;
    overflow: hidden;
	word-wrap: break-word;
}

#eventos .lista-eventos:hover{
	transform: scale(1.05);
}

.list-eventos a, .list-eventos a,:hover .list-eventos a:active{
	list-style: none;
	text-decoration: none;
}

.list-eventos .link-evento{
	background-color: #fff;
	height: 330px;
	transition: all 500ms;
}

.list-eventos .link-evento:hover{
	transform: scale(1.05);
}

.list-eventos .link-evento .description{
	padding-left: 30px;
	padding-right: 30px;
}


.list-eventos .link-evento .description p{
	font-size: 14px;
	line-height: 15px;
}

.list-eventos .link-evento .description .desc{
	font-size: 12px;
	margin-top: 5px;
	height: 40px;
	width: 100%;
	white-space: nowrap;
    text-overflow: ellipsis;
	display: block;
    overflow: hidden;
	word-wrap: break-word;
}


/*-----SEÇÃO APROVAÇÃO-----*/


#aprovacao{
	background-color: #fff;
}

#aprovacao .image{
	margin-bottom: -60px;
}

#aprovacao .image-sm-only{
	margin-bottom: -60px;
}



/*------CONTATO-----*/

.custom-file-label::after{
	content: "Procurar";
}

/*------FOOTER-----*/


footer{
	background-color: #3e4095;
}

footer .logo-footer{
	height: 110px;
	width: auto;
}

footer p{
	color: #fff;
}

footer .endereco{
	font-size: 12px;
	line-height: 10px;
}

footer .endereco .icon{
	height: 12px;
	fill: #b2b2d4;
}

footer .endereco a{
	color: #b2b2d4;
}

footer .nome-escola{
	line-height: 10px;
}


footer .nome-escola .min-text{
	font-size: 14px;
}

footer .nome-escola .max-text{
	font-size: 24px;
	line-height: 5px;
}

footer .footer-links{
	height: 30px;
	width: 30px;
	border: solid 1px #fff;
	border-radius: 100%;
	transition: 500ms all;
}

footer .footer-links:hover{
	background-color: #fff;
}

footer .footer-links:hover {
	color: #3e4095;
}

footer .footer-links{
	color: #fff;
	font-size: 12px;
}

footer .bottom-bar{
	background-color: #313385;
}

footer .bottom-bar p{
	font-size: 12px;
	margin: 0 !important;
}

footer .sauto{
	height: 30px;
	fill: #fff;
}


/*-----ERROR PAGE-----*/


#page_error{
	padding-top: 100px;
}

#error_info{
	color: #3e4095;
}

#error_info img{
	height: 100px;
}

#number_error{
	font-size: 150px;
}

.btn-error, .btn-error:focus{
	padding-top: 7px;
	padding-bottom: 7px;
	padding-left: 35px !important;
	padding-right: 35px !important;
	background-color: transparent;
	color: #3e4095 !important;
	border: 2px solid #3e4095;
	font-weight: bold !important;
}

#areas{ box-shadow:-1px 3px 19px #ddd; }

#areas ul{
	display:flex;
	flex-direction:row;
	list-style: none;
}

#areas ul > li{ flex:1 1; }

#areas ul > li + li{ border-left:1px solid #ddd; }

#areas ul > li > a{
	height:108px;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	text-decoration:none;
	padding:.3rem;
}

#areas ul > li > a:hover{ background-color:#eee; }

#areas ul > li > a i{
	color:#004071;
	font-size:42px;
}

#areas ul > li > a span{
	color:#003f71;
	font-size:16px;
	margin-top:2px;
	font-weight:300;
	text-align:center;
}


/*------MEDIA QUERY-----*/


@media (max-width: 320px){
	.page-header, .page-header-dynamic{
		display: none;
	}

	.top-bar .only-sm.active{
		display: block;
		height: 50px;
	}

	.carousel-indicators li{
		margin-top: 0;
	}
	#historia .niveis{
		height: 270px;
	}
	.texts{
		font-size: 12px;
	}


	#historia .historia-text{
		margin-top: 1rem;
	}

	#historia .image{
		height: 350px;
	}
	#aprovacao .image{
		display: none;
	}
	#aprovacao iframe{
		width: 160px !important;
		height: 90px !important;
	}

	#aprovacao .aprovacao-text{
		width: 100% !important;
	}

	#cursos .lista-cursos{
		padding-top: 140px;
		height: 180px;
	}

	#cursos .lista-cursos h3{
		font-size: 22px;
	}

	.top-bar .hide-sm{
		display: none;
	}

	.page-text{
		font-size: 12px;
	}

	.noticia-foto{
		width: 120px;
	}

	footer .logo-footer{
		height: 100px;
	}

	footer .nome{
		margin-top: 15px !important;
	}

	footer .facebook-div{
		margin-top: 30px !important;
	}
}

@media (max-width: 360px) and (min-width: 320px){
	.page-header, .page-header-dynamic{
		display: none;
	}

	.top-bar .only-sm.active{
		display: block;
		height: 50px;
	}

	.carousel-indicators li{
		margin-top: 0;
	}
	#historia .niveis{
		height: 290px;
	}

	#historia .historia-text{
		margin-top: 1rem;
	}

	.texts{
		font-size: 12px;
	}
	#historia .image{
		height: 350px;
	}
	#aprovacao .image{
		display: none;
	}

	#aprovacao iframe{
		width: 160px !important;
		height: 90px !important;
	}


	#aprovacao .aprovacao-text{
		width: 100% !important;
	}

	#cursos .lista-cursos{
		padding-top: 120px;
		height: 180px;
	}

	.page-text{
		font-size: 14px;
	}


	.noticia-foto{
		width: 120px;
	}


	.top-bar .hide-sm{
		display: none;
	}

	footer .facebook-div{
		margin-top: 30px !important;
	}
}


@media (max-width: 437px) and (min-width: 361px){
	.page-header, .page-header-dynamic{
		display: none;
	}

	.top-bar .hide-sm{
		display: none;
	}

	.top-bar .only-sm.active{
		display: block;
		height: 50px;
	}

	.carousel-indicators li{
		margin-top: 0;
	}
	#historia .niveis{
		height: 380px;
	}
	#historia .niveis .icon{
		height: 40px;
	}
	#historia .niveis p{
		font-size: 12px;
	}

	#historia .historia-text{
		margin-top: 1rem;
	}

	.texts{
		font-size: 12px;
	}

	#historia .image{
		height: 350px;
	}

	#noticias .link-noticia{
		height: 360px;
	}

	#aprovacao .image{
		display: none;
	}

	#aprovacao iframe{
		width: 160px !important;
		height: 90px !important;
	}

	#aprovacao .aprovacao-text{
		width: 100% !important;
	}

	#cursos .lista-cursos{
		padding-top: 120px;
		height: 180px;
	}

	.noticia-foto{
		width: 120px;
	}

	.page-text{
		font-size: 14px;
	}

	footer .facebook-div{
		margin-top: 30px !important;
	}
}

@media (max-width: 575px) and (min-width: 438px){
	.page-header, .page-header-dynamic{
		display: none;
	}

	.carousel-indicators li{
		margin-top: 0;
	}
	#historia .niveis{
		height: 380px;
	}
	#historia .niveis .icon{
		height: 40px;
	}
	#historia .niveis p{
		font-size: 12px;
	}

	#historia .historia-text{
		margin-top: 1rem;
	}

	.texts{
		font-size: 12px;
	}

	#historia .image{
		height: 350px;
	}

	#noticias .link-noticia{
		height: 360px;
	}

	#aprovacao .image{
		display: none;
	}

	#aprovacao iframe{
		width: 320px !important;
		height: 160px !important;
	}

	#aprovacao .aprovacao-text{
		width: 100% !important;
	}

	#cursos .lista-cursos{
		padding-top: 120px;
		height: 180px;
	}

	.noticia-foto{
		width: 120px;
	}

	footer .facebook-div{
		margin-top: 30px !important;
	}
}


@media (max-width: 767px) and (min-width: 576px){
	.page-header-dynamic.nivel{
		height: 280px;
	}

	.page-header-dynamic.nivel .overlay .nivel-wrap .nivel-phrase{
		font-size: 20px;
		line-height: 20px;
		margin-bottom: 10px !important;
	}

	.page-header-dynamic.nivel .overlay .nivel-wrap .phrase-autor{
		font-size: 16px;
	}

	.carousel-indicators li{
		margin-top: 0;
	}
	#historia .niveis{
		height: 250px;
	}

	#historia .niveis .icon{
		height: 40px;
	}
	#historia .niveis p{
		font-size: 12px;
	}

	#historia .historia-text{
		margin-top: 1rem;
	}
	#aprovacao .image{
		display: none;
	}

	#aprovacao .aprovacao-text{
		width: 100% !important;
	}
	#cursos .lista-cursos{
		padding-top: 140px;
		height: 180px;
	}


	#cursos .lista-cursos h3{
		font-size: 20px;
	}

	footer .logo-footer{
		height: 120px;
	}

	footer .facebook-div{
		margin-top: 30px !important;
	}
}

@media (max-width: 929px) and (min-width: 768px){
	.page-header-dynamic.nivel{
		height: 300px;
	}

	.page-header-dynamic.nivel .overlay .nivel-wrap .nivel-phrase{
		font-size: 20px;
		line-height: 22px;
		margin-bottom: 10px !important;
	}

	.page-header-dynamic.nivel .overlay .nivel-wrap .phrase-autor{
		font-size: 16px;
	}

	.carousel-indicators li{
		margin-top: 0;
	}
	#historia .niveis{
		height: 180px;
	}
	#historia .niveis .icon{
		height: 30px;
	}
	#historia .niveis p{
		font-size: 12px;
	}

	#historia .historia-text{
		margin-top: 80px;
	}

	.navbar .nav-link{
		font-size: 10px;
	}

	.texts{
		font-size: 12px;
	}

	#historia .image{
		height: 350px;
	}

	#aprovacao .image-sm-only{
		display: none;
	}

	#aprovacao iframe{
		height: 90px !important;
		width: 160px !important;
	}

	#aprovacao .texts{
		line-height: 12px;
	}

	footer .endereco p, footer .endereco a{
		font-size: 10px;
	}

	footer .logo-footer{
		height: 85px;
	}

	footer .nome .max-text{
		margin-top: -8px;
		margin-left: 10px;
		font-size: 16px;
	}

	footer .nome .min-text{
		margin-top: -8px;
		margin-left: 10px;
		font-size: 12px;
	}
}

@media (max-width: 990px) and (min-width: 930px){
	.page-header-dynamic.nivel{
		height: 300px;
	}

	.page-header-dynamic.nivel .overlay .nivel-wrap .nivel-phrase{
		font-size: 20px;
		line-height: 22px;
		margin-bottom: 10px !important;
	}

	.page-header-dynamic.nivel .overlay .nivel-wrap .phrase-autor{
		font-size: 16px;
	}

	.carousel-indicators li{
		margin-top: 0;
	}
	#historia .niveis{
		height: 180px;
	}
	#historia .niveis .icon{
		height: 30px;
	}
	#historia .niveis p{
		font-size: 12px;
	}

	#historia .historia-text{
		margin-top: 80px;
	}

	.navbar .nav-link{
		font-size: 10px;
	}

	.texts{
		font-size: 12px;
	}

	#historia .image{
		height: 350px;
	}

	#aprovacao .image-sm-only{
		display: none;
	}

	#aprovacao iframe{
		height: 90px !important;
		width: 160px !important;
		margin-top: 20px;
	}

	#aprovacao .texts{
		line-height: 14px;
	}

	footer .endereco p, footer .endereco a{
		font-size: 10px;
	}

	footer .logo-footer{
		height: 85px;
	}

	footer .nome .max-text{
		margin-top: -8px;
		margin-left: 10px;
		font-size: 16px;
	}

	footer .nome .min-text{
		margin-top: -8px;
		margin-left: 10px;
		font-size: 12px;
	}
}

@media (max-width: 1199px) and (min-width: 991px){
	.page-header-dynamic.nivel{
		height: 350px;
	}

	.page-header-dynamic.nivel .overlay .nivel-wrap .nivel-phrase{
		font-size: 26px;
		line-height: 28px;
		margin-bottom: 10px !important;
	}

	.page-header-dynamic.nivel .overlay .nivel-wrap .phrase-autor{
		font-size: 16px;
	}

	.texts{
		font-size: 12px;
	}
	#historia .image{
		height: 350px;
	}

	#historia .historia-text{
		margin-top: 80px;
	}

	#historia .niveis p{
		line-height: 10px;
		font-size: 14px;
	}

	#aprovacao .image{
		margin-bottom: -60px !important;
	}
	#aprovacao iframe{
		width: 320px !important;
		height: 180px !important;
	}
	#aprovacao .image-sm-only{
		display: none;
	}
	footer .logo-footer{
		height: 100px;
	}

}

@media (max-width: 1300px) and (min-width: 1200px){
	.page-header-dynamic.nivel{
		height: 400px;
	}

	.page-header-dynamic.nivel .overlay .nivel-wrap .nivel-phrase{
		font-size: 26px;
		line-height: 28px;
		margin-bottom: 10px !important;
	}

	.page-header-dynamic.nivel .overlay .nivel-wrap .phrase-autor{
		font-size: 16px;
	}
	#aprovacao .image-sm-only{
		display: none;
	}

	#aprovacao iframe{
		height: 180px;
		width: 320px;
	}
}

@media (max-width: 1499px) and (min-width: 1301px){
	#aprovacao iframe{
		height: 270px;
		width: 480px;
		margin-top: -5px;
	}
	#aprovacao .image-sm-only{
		display: none;
	}

}

@media (max-width: 1599px) and (min-width: 1500px){
	#aprovacao iframe{
		height: 270px;
		width: 480px;
		margin-top: 40px;
	}
	#aprovacao .image-sm-only{
		display: none;
	}

}

@media (max-width: 1799px) and (min-width: 1600px){
	#aprovacao iframe{
		height: 360px !important;
		width: 640px;
		margin-top: 40px;
	}

	#aprovacao .image-sm-only{
		display: none;
	}
}

@media (max-width: 1899px) and (min-width: 1800px){
	#aprovacao iframe{
		height: 360px !important;
		width: 640px;
		margin-top: 60px;
	}

	#aprovacao .image-sm-only{
		display: none;
	}
}

@media (min-width: 1900px){
	#aprovacao iframe{
		height: 360px !important;
		width: 640px;
		margin-top: 100px;
	}

	#aprovacao .image-sm-only{
		display: none;
	}
}